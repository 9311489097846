import { Routes, Route } from "react-router-dom";

const RenderComponent = (props) => {
  const { element: Component } = props;
  return <Component {...props} />;
};

const AppRoute = (props) => {
  const { appRoutes } = props;
  return (
    <Routes>
      {appRoutes?.map((route, index) => (
        <Route
          path={route.path}
          key={index}
          element={<RenderComponent {...route} />}
        />
      ))}
    </Routes>
  );
};

export default AppRoute;
