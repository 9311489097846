import React from "react";

const About = React.lazy(() => import("../core/About"));
const Home = React.lazy(() => import("../core/Home"));
const Projects = React.lazy(() => import("../core/Projects"));
const Resume = React.lazy(() => import("../core/Resume"));
const Contact = React.lazy(() => import("../core/Contact"));
const Blogs = React.lazy(() => import("../core/Blogs"));

export const portfolioRoutes = [
  {
    path: "/",
    title: "Home",
    element: Home,
  },
  {
    path: "/about",
    title: "About",
    element: About,
  },
  {
    path: "/projects",
    title: "Projects",
    element: Projects,
  },
  {
    path: "/resume",
    title: "Resume",
    element: Resume,
  },
  {
    path: "/contact",
    title: "Contact",
    element: Contact,
  },
  {
    path: "/blogs",
    title: "Blogs",
    element: Blogs,
  },
];
