import React, { useState } from "react";
import "../assets/css/navbar.scss";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-final.svg";
import sun from "../assets/images/sun.svg";
import moon from "../assets/images/moon.svg";

const Navbar = () => {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("theme")
  );

  const changeTheme = (e) => {
    e.preventDefault();
    if (currentTheme === "light") {
      document.documentElement.setAttribute("theme", "dark");
      localStorage.setItem("theme", "dark");
      setCurrentTheme("dark");
    } else {
      document.documentElement.removeAttribute("theme");
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");
    }
  };
  return (
    <nav className="navbar">
      <div className="logo-section">
        <Link to={"/"}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="navbar-links">
        <div className="link-items">
          <Link to={"/about"} className="link-items">
            About
          </Link>
          <Link to={"/projects"} className="link-items">
            Projects
          </Link>
          <Link to={"/resume"} className="link-items">
            Resume
          </Link>
          <Link to={"/blogs"} className="link-items">
            Blogs
          </Link>
          <Link to={"/contact"} className="link-items">
            Contact
          </Link>
        </div>
        <div className="theme-toggle">
          <Link to={""} onClick={changeTheme}>
            {currentTheme === "dark" ? (
              <img src={sun} alt="" />
            ) : (
              <img src={moon} alt="" />
            )}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
