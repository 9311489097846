import Navbar from "./core/Navbar";
import { appRoutes } from "./routes";
import AppRoute from "./routes/AppRoute";
import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  useEffect(() => {
    const current = localStorage.getItem("theme");
    if (current === "dark") {
      document.documentElement.setAttribute("theme", "dark");
    } else {
      document.documentElement.removeAttribute("theme");
    }
  }, []);
  return (
    <React.Suspense fallback={""}>
      <Navbar />
      <AppRoute appRoutes={appRoutes} />
    </React.Suspense>
  );
}

export default App;
